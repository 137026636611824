<template>
  <UDropdown
    id="languageDropdown"
    class="w-full"
    screen-reader-text="Open Language Menu"
    :items="languageOptions"
  >
    <span class="sr-only">Open Language Menu</span>
    <div class="flex w-full items-center">
      <UIcon
        name="i-mdi-earth"
        class="mr-1.5 size-5 shrink-0 dark:text-white"
      />
      <span class="truncate">{{ localeProperties.name }}</span>
      <UIcon
        name="i-mdi-chevron-down"
        class="ms-auto size-4 shrink-0 dark:text-white"
      />
    </div>
  </UDropdown>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import 'dayjs/locale/fr-ca'
import en from 'primelocale/en.json'
import fr from 'primelocale/fr.json'

import { usePrimeVue } from 'primevue/config'
import { type HubDropdownItem } from '~/types/component'

const { locale, locales, setLocale, localeProperties } = useI18n()
const primeVue = usePrimeVue()
const primeLocales = ref({ ...en, ...fr })

const availableLocales: Ref<Array<any>> = computed(() => {
  const languages = locales.value as Array<any>
  return languages.filter(i => i.code !== locale.value)
})

const languageOptions: Ref<Array<Array<HubDropdownItem>>> = computed(() => {
  return availableLocales.value.map((locale: any) => [
    {
      label: locale.name || '',
      click: () => {
        setLocale(locale.code)
        dayjs.locale(locale.code.toLowerCase())

        // @ts-expect-error we're importing from their own library, I'd be concerned if it didn't work!
        primeVue.config.locale = primeLocales.value[locale.code.split('-')[0]]
      }
    }
  ])
})
</script>

<style scoped></style>
